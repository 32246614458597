body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.body {
  /* margin: 1rem; */
}

h1,
h2,
h3 {
  color: #f0582a;
}
