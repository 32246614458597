ul.unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #f0582a, #eb0685);
}

nav li {
  float: left;
}

nav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

nav li a:hover {
  background-color: #d9390d;
}

/* nav button {
  margin-top: 13px;
} */

.auth-btn {
  float: right;
  padding: 0.1em;
  margin: 0;
}
